<template>
  <v-container>
    <DashboardHeader icon="mdi-hand-heart" title="හඳහන් ගලපන්න" />

    <div class="note mb-2">
      <v-icon left>mdi-menu-right</v-icon>
      <p>
        පෞද්ගලිකත්වයද රැකෙන සේ ඔබ තෝරාගත් අයගේ පොරොන්දම් කෙටි කාලයක් තුලදී ගළපා බැලීමට පහත ආකාරයට ගෙවීමක් සිදුකර,
        සාමාජික අංකය සටහන් කරන ලද රිසිට් පත 0717 928 628 අංකයට Whatsapp/Viber/Imo හෝ ashirwadaproposals@gmail.com
        ක්‍රමයක් ඔස්සේ අප වෙත යොමු කර, ගළපා බැලීමට අවශ්‍ය සාමාජික අංකද සමග 0717 928 627 අමතන්න.
      </p>
    </div>

    <p class="mb-1">
      <v-icon left>mdi-menu-right</v-icon>එක් අයෙකු සඳහා රු. 200.00.
    </p>
    <p
      class="pl-8 pb-2"
    >උදා: ඔබට තිදෙනෙකුගේ ගැලපීම් කර ගැනීමට අවශ්‍ය නම් රු.600 ක් ලෙස ගෙවීම් සිදුකර තිබිය යුතුය.</p>
    <div>
      <p>
        <v-icon left>mdi-menu-right</v-icon>ගෙවීම් කිරීමට :
      </p>
      <p class="ml-12">නම : ආශීර්වාද මංගල යෝජනා ආයතනය</p>
      <p class="ml-12">ශාඛාව : BOC, කඩවත</p>
      <p class="ml-12">ගිණුම් අංකය : 85207981</p>
    </div>

    <p
      class="ml-2 mt-7 font-italic body-2 error--text"
    >(පැකේජයක් ලබාගත් සාමාජිකයන්ට පමණක් මෙම සේවාව ලබාගත හැක)</p>
  </v-container>
</template>

<script>
import DashboardHeader from "../components/DashboardHeader";

export default {
  components: {
    DashboardHeader
  }
};
</script>

<style scoped>
.note {
  display: flex;
  align-items: flex-start;
}
</style>